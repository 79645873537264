import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

import WhyJoinBubbles from '../../components/academy/WhyJoinBubbles';
import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const WhyJoin = ({ academyImages }) => {
  return (
    <Box className='body-layout'>
      <Flex flexWrap='wrap' pt='75px' pb='95px' style={{ position: 'relative', zIndex: '0' }}>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
          <Text color='nextonBlue' fontSize={['40px', '50px']} letterSpacing='-1.5px' pb='10px'>
            Why Join?
          </Text>
          <Text color='nextonOceanBlue' fontSize={['32px', '36px']} lineHeight='1.2' letterSpacing='-1.5px' pb='35px'>
            The Tech Industry needs you
          </Text>
          <Text fontSize={['20px', '22px']} lineHeight='1.5' letterSpacing='-0.5px'>
            The shortage of tech talent is not a new problem. Most people know there is a high demand for Engineers and
            other technical roles, but with the industry’s tremendous growth, non-technical roles are also at their
            peak. It’s a market that, while it offers great growth opportunities and benefits, is also becoming more and
            more competitive.
            <br />
            <br />
            That’s why, unlike other similar programs in the market, Nexton Academy offers a non-technical training
            designed for those who would love to work in the Tech Industry. We will also be offering full-time paid
            internships in USD to the most exceptional participants at the end of the program.
          </Text>
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
          <Box mt='50px'>
            <GatsbyImage image={academyImages.whyJoin} alt='Why Join' />
          </Box>
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} pt={['50px', '50px']} mb={['0px', '0px', '-150px']}>
          <WhiteRoundCard2 padding='0px'>
            <Box px={['0px', '35px']} pt={['10px', '30px']} pb={['0px', '30px']}>
              <Text color='nextonBlue' fontSize={['32px', '32px']} letterSpacing='-1px' fontWeight='500' mb='15px'>
                Take Away
              </Text>
              <Text color='nextonOceanBlue' fontSize={['22px', '24px']} lineHeight='1' letterSpacing='-1px' mb='25px'>
                In this program you will:
              </Text>
              <Text fontSize={['20px', '20px']} lineHeight='1.2' letterSpacing='-0.5px' ml='10px'>
                •&#xa0; Get an introduction to the IT World & start-ups
                <br />
                •&#xa0; Develop specific tools and knowledge to succeed in IT
                <br />
                •&#xa0; Get the chance to land a full-time paid internship at Nexton
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} pt={['50px', '50px']} mb='-150px'>
          <WhiteRoundCard2 padding='0px'>
            <Box px={['0px', '35px']} pt={['10px', '30px']} pb={['0px', '30px']}>
              <Text color='nextonBlue' fontSize={['32px', '32px']} letterSpacing='-1px' fontWeight='500' mb='15px'>
                Requirements
              </Text>
              <Text color='nextonOceanBlue' fontSize={['22px', '24px']} lineHeight='1' letterSpacing='-1px' mb='25px'>
                To participate in the program, you need:
              </Text>
              <Text fontSize={['20px', '20px']} lineHeight='1.2' letterSpacing='-0.5px' ml='10px'>
                •&#xa0; Equipment: A computer with stable internet connection
                <br />
                •&#xa0; Time commitment: 5 hours per week during January 2022
                <br />
                •&#xa0; The desire to learn and grow! {/* <strong>No experience in IT companies is required</strong> */}
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <WhyJoinBubbles />
      </Flex>
    </Box>
  );
};

export default WhyJoin;
