import React from 'react';
import styled from 'styled-components';

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const StyledItem = styled(AccordionItem)`
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.160047);
  border-radius: 8px;
  background-color: white;
  padding: 18px;
  margin-top: 25px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: -1px;
`;

const StyledHeading = styled(AccordionItemHeading)`
  color: ${({ theme }) => theme.colors.nextonBlue};
  font-size: 26px;
  cursor: pointer;
`;

const StyledPanel = styled(AccordionItemPanel)`
  margin-top: 14px;
  transition: display 3s;
  animation: fadein 0.35s ease-in;

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const FAQItem = ({ heading, body }) => (
  <StyledItem>
    <StyledHeading>
      <AccordionItemButton>{heading}</AccordionItemButton>
    </StyledHeading>
    <StyledPanel>{body}</StyledPanel>
  </StyledItem>
);

export default FAQItem;
