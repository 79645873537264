import React from 'react';
import { Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

const OurProgram = ({ academyImages }) => (
  <Flex flexDirection='column' alignItems='center' justifyContent='center' pt='75px' pb='40px' px={['0px', '20px']}>
    <Text
      color='nextonDarkGrey'
      fontSize={['32px', '50px']}
      lineHeight='1.3'
      letterSpacing='-1.5px'
      textAlign='center'
      // maxWidth="905px"
      mb='50px'>
      Our program is designed to give you the tools, skills and knowledge needed to thrive in the tech industry.
    </Text>
    <Text color='nextonBlue' fontSize={['28px', '36px']} letterSpacing='-1.5px' textAlign='center' mb='5px'>
      This program is for you if you:
    </Text>
    <Flex flexWrap='wrap'>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        width={[1, 1 / 2, 1 / 3]}
        my='35px'>
        <GatsbyImage image={academyImages.techIcon} alt='tech icon' />
        <Text fontSize={['22px', '22px']} lineHeight='1.3' textAlign='center' mt='35px'>
          Are a tech/start-up enthusiast
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        width={[1, 1 / 2, 1 / 3]}
        my='35px'>
        <GatsbyImage image={academyImages.remoteIcon} alt='remote icon' />
        <Text fontSize={['22px', '22px']} lineHeight='1.3' textAlign='center' mt='35px'>
          Love to work remotely
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        width={[1, 1 / 2, 1 / 3]}
        my='35px'>
        <GatsbyImage image={academyImages.challengeIcon} alt='challenge icon' />
        <Text fontSize={['22px', '22px']} lineHeight='1.3' textAlign='center' mt='35px'>
          Are hungry for a challenge
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        width={[1, 1 / 2, 1 / 3]}
        my='35px'>
        <GatsbyImage image={academyImages.latinIcon} alt='latin icon' />
        <Text fontSize={['22px', '22px']} lineHeight='1.3' textAlign='center' mt='35px'>
          Live in Latin America
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        width={[1, 1 / 2, 1 / 3]}
        my='35px'>
        <GatsbyImage image={academyImages.speakIcon} alt='speak icon' />
        <Text fontSize={['22px', '22px']} lineHeight='1.3' textAlign='center' mt='35px'>
          Speak Spanish and English fluently
        </Text>
      </Flex>
      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        width={[1, 1 / 2, 1 / 3]}
        my='35px'>
        <GatsbyImage image={academyImages.workIcon} alt='work icon' />
        <Text fontSize={['22px', '22px']} lineHeight='1.3' textAlign='center' mt='35px'>
          Have 2+ years of work experience
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

export default OurProgram;
