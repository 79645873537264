import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box } from 'rebass';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';
import AcademyHero from '../sections/academy/AcademyHero';
import OurProgram from '../sections/academy/OurProgram';
import WhyJoin from '../sections/academy/WhyJoin';
import LearnMore from '../sections/academy/LearnMore';
import FAQs from '../sections/academy/FAQs';

const camelCase = require('camelcase');

const AcademyPage = () => {
  const { academy } = useStaticQuery(graphql`
    {
      academy: allFile(filter: { relativeDirectory: { eq: "images/academy" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const academyImages = {};
  academy.nodes.forEach(img => {
    academyImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='Nexton Academy'
        description='The door to your first job in the tech industry'
        image='https://www.nextonlabs.com/academy-banner.png'
      />
      <Layout selected='academy'>
        <Box className='full-width' bg='nextonLightGrey' mb='75px'>
          <AcademyHero academyImages={academyImages} />
        </Box>
        <OurProgram academyImages={academyImages} />
        <Box className='full-width' bg='#FAFAFA' mb='75px'>
          <WhyJoin academyImages={academyImages} />
        </Box>
        {/* <Timeline academyImages={academyImages} /> */}
        <LearnMore />
        <Box className='full-width' bg='nextonLightGrey'>
          <FAQs />
        </Box>
        {/* <Interested /> */}
      </Layout>
    </>
  );
};

export default AcademyPage;
