import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { Accordion } from 'react-accessible-accordion';
import FAQItem from '../../components/academy/FAQItem';

// Demo styles, see 'Styles' section below for some notes on use.
// import 'react-accessible-accordion/dist/fancy-example.css';

const FAQs = () => (
  <Box className='body-layout'>
    <Flex flexDirection='column' alignItems='center' justifyContent='center' py='75px' px={['0px', '20px']}>
      <Text color='nextonBlue' fontSize={['40px', '50px']} letterSpacing='-1.5px' mb='30px'>
        FAQ’s
      </Text>
      <Box maxWidth='905px'>
        <Accordion allowZeroExpanded allowMultipleExpanded>
          <FAQItem
            heading='Do I need to know how to code or have some specific knowledge? '
            body='No prior knowledge is needed. What matters most is commitment, a great attitude and the desire to work as a team.'
          />
          <FAQItem
            heading='What about the work experience requirement?'
            body='Anyone with at least 2 years of work experience can apply. Students are allowed as long as they meet this requirement. We’ll consider people from all backgrounds, including Tourism, Translators, HR, Business Administration, Marketing, Sales and Customer Support.'
          />
          <FAQItem
            heading='What English/Spanish level is required?'
            body='An advanced level of each language is required. You should feel very comfortable both in verbal and written form, since you’ll be interacting with a diverse and multicultural team.'
          />
          <FAQItem
            heading='Does participating have a monetary cost?'
            body='No, our program is free. In fact, we’ll also be offering full-time paid internships in USD to the most exceptional participants at the end of the program.'
          />
          <FAQItem
            heading='On what days and times will the meetings be?'
            body='The estimated hourly workload is 5 hours per week. We will have at least 2 synchronous meetings of 1 hour and asynchronous material in a virtual campus.'
          />
          <FAQItem
            heading='I am not interested in an internship, but I would like to participate in the Academy. Can I join anyway?'
            body='In short, no. We’re hoping to onboard candidates willing to make a professional transition into the tech industry with us at Nexton.'
          />
          <FAQItem
            heading='If I participate in the training, will I automatically get a full-time paid internship?'
            body='No. We will hold a feedback session and final interview with all participants at the end of the process. Internships will be offered to the most outstanding participants, based on their performance during the training, team activities and final interview.'
          />
          <FAQItem
            heading='What will the internship be like, if I get chosen?'
            body='It’s a full-time contract at Nexton, with a salary in USD. You will be allocated within one of our teams, such as Recruiting Services, Customer Operations, Marketing, Product or Sales, with specific tasks to fulfill. There are many growth opportunities within Nexton, the Internship is just the first step!'
          />
          <FAQItem
            heading='Is the internship paid?  What is the salary range?'
            body='Yes, the internship is paid. The salary range for the first three months is 300-400 USD. Then it will be renegotiated according to the career path of your designated area.'
          />
          <FAQItem
            heading='Will I get a certificate if I complete the training?'
            body='Yes, we will give you a certificate if you complete the training. We hope that even if you don’t get selected to work with us at Nexton, this experience will help you find other great opportunities within the IT world.'
          />
        </Accordion>
      </Box>
    </Flex>
  </Box>
);

export default FAQs;
