import React from 'react';
import { Box, Flex, Text, Button } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link as GatsbyLink } from 'gatsby';

import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const AcademyHero = ({ academyImages }) => (
  <Box className='body-layout'>
    <Flex flexWrap='wrap' py='75px'>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <GatsbyImage image={academyImages.logo} alt='Nexton Academy Logo' />
        <Text
          as='h1'
          fontWeight='400'
          color='nextonOceanBlue'
          fontSize={['50px', '65px']}
          lineHeight='1'
          letterSpacing='-1.5px'
          pt='35px'
          pb='30px'>
          The door to your first job in the tech industry
        </Text>
        <Text color='nextonDarkGrey' fontSize={['22px', '28px']} lineHeight='1.2' letterSpacing='-1.5px'>
          Are you a young professional who’s curious about the IT world? Have you been thinking about transitioning into
          the tech industry?
          <br />
          <br />
          Then Nexton Academy is for you!
          <br />
          <br />
          {/* <Text color="nextonOceanBlue" fontWeight="500" fontStyle="italic">
            Hurry up! Applications are open until 6th December!
          </Text> */}
        </Text>
        {/* <GatsbyLink to="/academy-apply">
          <Button variant="newMainOrange" mt="40px">
            APPLY NOW
          </Button>
        </GatsbyLink> */}
      </Box>
      <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
        <Box mt='55px'>
          <GatsbyImage image={academyImages.academyStairs} alt='Academy Stairs' />
        </Box>
      </Box>
      <Box width={[1]} px={['0px', '20px']} mt='80px' mb='-150px'>
        <WhiteRoundCard2 padding='45px 35px' maxWidth='1120px'>
          <Box>
            <Flex
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              textAlign='center'
              mt={['20px', '0px']}
              mb={['20px', '10px']}>
              <Text color='nextonOceanBlue' fontSize={['32px', '36px']} letterSpacing='-1.5px' pb='30px'>
                Applications for Nexton Academy are closed for now.
              </Text>
              <Text
                color='nextonDarkGrey'
                fontSize={['22px', '28px']}
                lineHeight='1.3'
                letterSpacing='-1.5px'
                textAlign='center'
                maxWidth='750px'>
                But if you’d like to stay tuned with all the news about our program feel free to leave your contact
                information here:
              </Text>
              <GatsbyLink to='/academy-contact'>
                <Button variant='newMain' mt='35px'>
                  LEAVE CONTACT
                </Button>
              </GatsbyLink>
            </Flex>
          </Box>
        </WhiteRoundCard2>
      </Box>
    </Flex>
  </Box>
);

export default AcademyHero;
