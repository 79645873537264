import React from 'react';
import { Flex, Text, Button } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';

const LearnMore = () => (
  <Flex flexDirection='column' alignItems='center' justifyContent='center' py='75px' px={['0px', '20px']}>
    <Text color='nextonBlue' fontSize={['40px', '50px']} letterSpacing='-1.5px' pb='25px'>
      About Us
    </Text>
    <Text
      color='nextonDarkGrey'
      fontSize={['22px', '28px']}
      lineHeight='1.3'
      letterSpacing='-1.5px'
      textAlign='center'
      maxWidth='905px'>
      We’re a successful team innovating in the Recruiting industry and creating the future of remote work. At Nexton we
      connect US companies with the best engineers in Latin America through our talent network.
    </Text>
    <GatsbyLink to='/about'>
      <Button variant='newMain' mt='24px'>
        LEARN MORE ABOUT US
      </Button>
    </GatsbyLink>
  </Flex>
);

export default LearnMore;
